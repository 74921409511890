import React, { ReactElement } from "react";

export default function HowThisWorkImageIcon(): ReactElement {
    return (
        <svg
            width="593"
            height="554"
            viewBox="0 0 593 554"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M250.735 234.124L1.10948 233.317C0.923831 232.772 0.754868 232.215 0.602634 231.648C-0.69229 226.727 -0.00166437 220.996 3.66589 217.385C7.33345 213.774 14.34 213.432 17.4673 217.502C19.1131 209.549 20.8313 201.409 25.1811 194.491C29.531 187.572 37.1223 182 45.4516 182.25C53.7809 182.501 61.7648 190.257 60.0243 198.19C63.9982 184.877 68.2478 171.073 77.7829 160.775C87.318 150.476 103.793 144.851 116.185 151.678C123.256 155.572 127.848 162.825 130.128 170.414C132.409 178.003 132.649 186.011 132.871 193.915C133.788 187.831 138.998 182.593 145.211 181.497C159.413 178.993 165.582 192.018 165.389 203.662C168.174 201.039 170.992 197.309 174.301 195.317C181.174 191.183 194.056 192.07 199.587 198.9C203.268 203.445 203.588 210.305 200.344 215.149C218.985 212.097 242.105 217.004 250.735 234.124Z"
                fill="#FEF1EF"
            />
            <path
                d="M274.016 120.079L562.901 119.127C563.122 118.486 563.317 117.836 563.488 117.18C564.986 111.369 564.187 104.603 559.935 100.339C555.682 96.074 547.584 95.6734 543.967 100.478C542.059 91.089 540.068 81.4804 535.036 73.3101C530.004 65.1398 521.218 58.5663 511.577 58.8445C501.936 59.1227 492.699 68.3028 494.712 77.6637C490.115 61.9462 485.197 45.6474 474.164 33.4879C463.13 21.3284 444.063 14.6826 429.727 22.7444C421.545 27.34 416.232 35.9025 413.592 44.8601C410.952 53.8177 410.676 63.276 410.42 72.6063C409.356 65.4236 403.333 59.2534 396.139 57.9459C379.695 54.986 372.561 70.3669 372.786 84.1148C369.559 81.0186 366.3 76.6038 362.483 74.2699C354.529 69.3877 339.619 70.4365 333.22 78.4983C328.959 83.8645 328.589 91.9541 332.34 97.6931C310.758 94.0767 284.002 99.8685 274.016 120.079Z"
                fill="#FEF1EF"
            />
            <path
                d="M299.402 532.767C461.551 532.767 592.999 523.046 592.999 511.054C592.999 499.063 461.551 489.342 299.402 489.342C137.253 489.342 5.80467 499.063 5.80467 511.054C5.80467 523.046 137.253 532.767 299.402 532.767Z"
                fill="#FEF1EF"
            />
            <path
                d="M463.495 59.3564H144.125C133.871 59.3564 125.559 67.6601 125.559 77.9031V495.615C125.559 505.858 133.871 514.162 144.125 514.162H463.495C473.749 514.162 482.061 505.858 482.061 495.615V77.9031C482.061 67.6601 473.749 59.3564 463.495 59.3564Z"
                fill="#EA7969"
            />
            <path
                d="M455.032 59.3564H135.662C125.408 59.3564 117.096 67.6601 117.096 77.9031V495.615C117.096 505.858 125.408 514.162 135.662 514.162H455.032C465.286 514.162 473.598 505.858 473.598 495.615V77.9031C473.598 67.6601 465.286 59.3564 455.032 59.3564Z"
                fill="black"
            />
            <path
                d="M459.265 59.3564H139.895C129.641 59.3564 121.329 67.6601 121.329 77.9031V495.615C121.329 505.858 129.641 514.162 139.895 514.162H459.265C469.519 514.162 477.831 505.858 477.831 495.615V77.9031C477.831 67.6601 469.519 59.3564 459.265 59.3564Z"
                fill="#444B53"
            />
            <path
                d="M442.425 490.218L146.503 477.936L162.833 75.6386L460.613 89.6397L442.425 490.218Z"
                fill="#EA7969"
            />
            <path
                d="M459.112 477.035L160.474 490.113L147.099 90.2823L444.366 76.1254L459.112 477.035Z"
                fill="#EA7969"
            />
            <path
                d="M439.134 487.868L143.211 475.586L159.541 73.2908L457.321 87.2918L439.134 487.868Z"
                fill="white"
            />
            <path
                d="M439.916 487.868L143.994 475.586L160.327 73.2908L458.104 87.2918L439.916 487.868Z"
                fill="#EFEEEE"
            />
            <path
                d="M455.196 474.843L156.556 487.92L143.181 88.0902L440.448 73.9333L455.196 474.843Z"
                fill="white"
            />
            <path
                d="M455.511 474.862L157.339 487.918L143.972 88.0902L440.757 73.9639C440.819 73.9609 440.881 73.9703 440.939 73.9914C440.997 74.0125 441.051 74.0449 441.097 74.0869C441.142 74.1288 441.179 74.1794 441.205 74.2356C441.231 74.2919 441.245 74.3527 441.247 74.4146L455.959 474.384C455.962 474.507 455.917 474.625 455.833 474.715C455.749 474.804 455.634 474.857 455.511 474.862V474.862Z"
                fill="#EFEEEE"
            />
            <path
                d="M448.677 80.8657C448.677 80.741 448.628 80.6214 448.539 80.5333C448.451 80.4451 448.331 80.3956 448.207 80.3956H150.95C150.825 80.3956 150.706 80.4451 150.618 80.5333C150.529 80.6214 150.48 80.741 150.48 80.8657V481.33H448.207C448.331 481.33 448.451 481.281 448.539 481.193C448.628 481.104 448.677 480.985 448.677 480.86V80.8657Z"
                fill="#FAFAFA"
            />
            <path
                d="M448.209 80.3956C448.334 80.3956 448.453 80.4447 448.541 80.5323C448.629 80.6199 448.679 80.7387 448.68 80.8629L449.306 191.723C448.677 294.273 375.716 456.513 150.48 456.513V80.8657C150.48 80.741 150.529 80.6214 150.618 80.5333C150.706 80.4451 150.825 80.3956 150.95 80.3956H448.209Z"
                fill="white"
            />
            <path
                d="M374.131 51.7091C324.097 46.6239 330.31 0 297.227 0C264.143 0 270.359 46.6239 220.311 51.7091H207.222C206.41 51.7087 205.605 51.8683 204.854 52.1788C204.103 52.4893 203.42 52.9446 202.845 53.5186C202.27 54.0926 201.814 54.7741 201.503 55.5243C201.192 56.2744 201.032 57.0784 201.032 57.8904V83.7421C201.033 85.3818 201.685 86.954 202.846 88.1131C204.007 89.2723 205.581 89.9234 207.222 89.9234H387.256C388.069 89.9234 388.874 89.7635 389.625 89.4526C390.376 89.1418 391.058 88.6863 391.632 88.112C392.207 87.5377 392.663 86.856 392.974 86.1057C393.284 85.3554 393.444 84.5513 393.444 83.7394V57.8876C393.443 57.0752 393.283 56.2708 392.971 55.5204C392.659 54.7701 392.202 54.0885 391.626 53.5146C391.051 52.9408 390.367 52.486 389.616 52.1762C388.864 51.8663 388.058 51.7076 387.245 51.7091H374.131ZM298.639 40.3536C296.435 40.3536 294.282 39.701 292.45 38.4783C290.618 37.2556 289.19 35.5177 288.347 33.4844C287.504 31.4512 287.284 29.2138 287.713 27.0553C288.143 24.8968 289.204 22.9141 290.762 21.3579C292.32 19.8017 294.305 18.7419 296.465 18.3126C298.626 17.8832 300.866 18.1036 302.901 18.9458C304.937 19.788 306.676 21.2142 307.9 23.0441C309.124 24.874 309.778 27.0254 309.778 29.2262C309.777 32.1768 308.603 35.0064 306.514 37.0926C304.425 39.1788 301.592 40.3508 298.639 40.3508V40.3536Z"
                fill="#444B53"
            />
            <path
                d="M378.991 51.7091C328.954 46.6267 335.175 0.00280762 302.086 0.00280762C268.997 0.00280762 275.219 46.6239 225.182 51.7091H212.068C210.427 51.7091 208.853 52.3604 207.693 53.5196C206.532 54.6788 205.88 56.251 205.88 57.8904V83.7422C205.88 84.5542 206.04 85.3582 206.35 86.1085C206.661 86.8588 207.117 87.5405 207.692 88.1148C208.266 88.6891 208.949 89.1446 209.699 89.4554C210.45 89.7663 211.255 89.9262 212.068 89.9262H392.104C392.917 89.9262 393.722 89.7663 394.473 89.4554C395.224 89.1446 395.906 88.6891 396.481 88.1148C397.055 87.5405 397.511 86.8588 397.822 86.1085C398.133 85.3582 398.292 84.5542 398.292 83.7422V57.8876C398.291 56.2487 397.639 54.6772 396.479 53.5186C395.318 52.36 393.745 51.7091 392.104 51.7091H378.991ZM300.833 40.3536C298.63 40.3536 296.476 39.701 294.644 38.4783C292.813 37.2556 291.385 35.5177 290.542 33.4845C289.699 31.4512 289.478 29.2138 289.908 27.0553C290.338 24.8968 291.399 22.9141 292.956 21.3579C294.514 19.8017 296.499 18.7419 298.66 18.3126C300.821 17.8832 303.06 18.1036 305.096 18.9458C307.131 19.788 308.871 21.2142 310.095 23.0441C311.319 24.874 311.972 27.0254 311.972 29.2262C311.971 32.1769 310.797 35.0065 308.709 37.0927C306.62 39.1789 303.787 40.3508 300.833 40.3508V40.3536Z"
                fill="#E3282A"
            />
            <path
                d="M333.501 26.4471C323.704 13.2861 317.244 0 299.58 0C280.086 0 274.238 16.1681 262.5 30.5003C258.064 35.9193 252.787 41.0713 245.614 44.9909C237.455 55.2651 233.023 67.9989 233.043 81.1133C233.043 81.283 233.043 81.4471 233.043 81.614H305.542C305.384 79.8707 305.304 78.1089 305.302 76.3285C305.296 66.2905 307.89 56.4217 312.831 47.6812C317.772 38.9406 324.893 31.6256 333.501 26.4471ZM299.58 40.3564C297.377 40.3564 295.223 39.7037 293.391 38.4811C291.559 37.2584 290.132 35.5205 289.289 33.4872C288.445 31.4539 288.225 29.2166 288.655 27.0581C289.085 24.8996 290.145 22.9169 291.703 21.3607C293.261 19.8045 295.246 18.7447 297.407 18.3153C299.567 17.886 301.807 18.1063 303.842 18.9485C305.878 19.7908 307.618 21.217 308.842 23.0469C310.066 24.8768 310.719 27.0281 310.719 29.2289C310.717 32.1792 309.543 35.008 307.454 37.0936C305.366 39.1792 302.533 40.3508 299.58 40.3508V40.3564Z"
                fill="#E3282A"
            />
            <path
                d="M233.043 81.1133C233.041 84.0626 233.264 87.0078 233.709 89.9234H209.562C207.92 89.9234 206.345 89.2719 205.184 88.1122C204.023 86.9524 203.371 85.3795 203.371 83.7394V57.8876C203.371 57.0759 203.532 56.2722 203.843 55.5223C204.155 54.7725 204.611 54.0913 205.185 53.5176C205.76 52.9439 206.443 52.4889 207.194 52.1786C207.944 51.8683 208.749 51.7087 209.562 51.7091H222.675C232.107 50.7494 239.54 48.3152 245.614 44.9937C237.455 55.2672 233.024 67.9999 233.043 81.1133V81.1133Z"
                fill="#E3282A"
            />
            <path
                d="M203.374 81.614V83.7394C203.374 84.5513 203.533 85.3554 203.844 86.1057C204.155 86.8559 204.611 87.5377 205.185 88.112C205.76 88.6863 206.442 89.1418 207.193 89.4526C207.944 89.7634 208.749 89.9234 209.562 89.9234H389.595C390.408 89.9234 391.213 89.7634 391.964 89.4526C392.715 89.1418 393.397 88.6863 393.972 88.112C394.546 87.5377 395.002 86.8559 395.313 86.1057C395.624 85.3554 395.783 84.5513 395.783 83.7394V81.614H203.374Z"
                fill="#E3282A"
            />
            <path
                d="M306.918 89.9234H233.709C233.681 89.7426 233.656 89.5646 233.628 89.3838L233.511 88.5075C233.472 88.207 233.438 87.9066 233.405 87.6061C233.388 87.4754 233.374 87.3447 233.361 87.2111C233.34 87.0387 233.323 86.8662 233.31 86.6937C233.269 86.2987 233.238 85.9009 233.207 85.5031C233.207 85.3723 233.188 85.2444 233.179 85.1164C233.171 84.9884 233.16 84.8382 233.152 84.6936L233.121 84.2568L233.104 83.9091C233.104 83.7227 233.088 83.5391 233.082 83.3527C233.082 83.2108 233.082 83.0745 233.068 82.9215C233.068 82.4875 233.046 82.0536 233.043 81.6168H305.542C305.797 84.4155 306.257 87.1919 306.918 89.9234V89.9234Z"
                fill="#E3282A"
            />
            <path
                d="M363.758 89.9234H306.918C306.261 87.1906 305.806 84.4133 305.556 81.614H363.293C363.293 81.8922 363.293 82.1704 363.293 82.4486C363.289 84.9475 363.444 87.4442 363.758 89.9234V89.9234Z"
                fill="#E3282A"
            />
            <path
                d="M372.383 51.0221C366.571 60.1229 363.42 70.6644 363.285 81.4583H305.548C305.39 79.7132 305.31 77.9513 305.308 76.1728C305.303 66.1371 307.897 56.2708 312.837 47.5323C317.777 38.7938 324.896 31.4803 333.501 26.3025C341.541 37.0877 351.822 47.8146 372.383 51.0221Z"
                fill="#E3282A"
            />
            <path
                d="M395.786 81.614V83.7394C395.786 85.3795 395.133 86.9524 393.973 88.1122C392.812 89.2719 391.237 89.9234 389.595 89.9234H363.758C363.439 87.4408 363.279 84.9404 363.279 82.4375C363.279 82.1593 363.279 81.8811 363.279 81.6029L395.786 81.614Z"
                fill="#E3282A"
            />
            <path
                d="M395.786 57.8876V81.614H363.285C363.42 70.8202 366.571 60.2788 372.382 51.1777C373.706 51.3873 375.073 51.5635 376.482 51.7063H389.595C390.408 51.7059 391.213 51.8655 391.964 52.176C392.715 52.4865 393.398 52.9417 393.972 53.5158C394.547 54.0898 395.003 54.7713 395.314 55.5214C395.626 56.2716 395.786 57.0756 395.786 57.8876Z"
                fill="#E3282A"
            />
            <path
                d="M395.12 112.885H204.229V147.141H395.12V112.885Z"
                fill="#EFEEEE"
            />
            <path
                d="M337.829 163.951H177.693V175.326H337.829V163.951Z"
                fill="#444B53"
            />
            <path
                d="M420.712 195.837H178.639V186.685H420.712V195.837Z"
                fill="#EFEEEE"
            />
            <path
                d="M420.712 205.743H178.639V214.895H420.712V205.743Z"
                fill="#EFEEEE"
            />
            <path
                d="M289.869 223.825H178.639V232.978H289.869V223.825Z"
                fill="#EFEEEE"
            />
            <path
                d="M420.932 248.172H178.422V259.547H420.932V248.172Z"
                fill="#444B53"
            />
            <path
                d="M420.93 268.649H345.83H339.246H264.336H257.752H178.42V370.51H339.246H345.83H420.93V268.649ZM413.633 275.999V315.905H345.83V275.999H413.633ZM339.246 275.999V315.905H264.336V275.999H339.246ZM257.761 275.999V315.905H185.716V275.999H257.761ZM185.716 323.254H257.761V363.163H185.716V323.254ZM264.344 363.313V323.399H339.255V363.307L264.344 363.313ZM345.83 363.168V323.254H413.633V363.163L345.83 363.168Z"
                fill="#FEF1EF"
            />
            <path
                d="M420.93 268.649H345.83H339.246H264.336H257.752H178.42V370.51H339.246H345.83H376.908C379.201 368.099 381.433 365.649 383.605 363.16H345.83V323.254H412.319C415.399 317.989 418.269 312.677 420.93 307.317V268.649ZM257.761 363.163H185.716V323.254H257.761V363.163ZM257.761 315.905H185.716V275.999H257.761V315.905ZM339.246 363.307H264.336V323.399H339.246V363.307ZM339.246 315.905H264.336V275.999H339.246V315.905ZM345.83 315.905V275.999H413.633V315.905H345.83Z"
                fill="#EFEEEE"
            />
            <path
                d="M421.464 417.962H179.391V408.81H421.464V417.962Z"
                fill="#FEF1EF"
            />
            <path
                d="M179.391 408.81V417.962H315.567C320.821 415.041 325.899 411.991 330.8 408.81H179.391Z"
                fill="#EFEEEE"
            />
            <path
                d="M421.464 427.871H179.391V437.024H421.464V427.871Z"
                fill="#FEF1EF"
            />
            <path
                d="M179.391 427.871V437.024H273.202C281.076 434.229 288.642 431.178 295.901 427.871H179.391Z"
                fill="#EFEEEE"
            />
            <path
                d="M337.829 386.004H177.693V397.594H337.829V386.004Z"
                fill="#444B53"
            />
            <path
                d="M404.277 433.791C404.675 435.555 394.332 439.338 381.063 442.226C367.793 445.113 354.688 445.681 354.29 443.917C353.891 442.153 366.351 438.726 379.617 435.85C392.884 432.973 403.878 432.027 404.277 433.791Z"
                fill="#E3282A"
            />
            <path
                d="M394.733 344.691C393.979 343.811 393.123 343.022 392.182 342.343L391.831 340.34C391.794 340.127 391.695 339.929 391.547 339.771C391.398 339.613 391.207 339.501 390.996 339.45C389.421 339.053 387.792 338.915 386.173 339.041L384.324 338.023C384.132 337.918 383.914 337.869 383.696 337.882C383.477 337.895 383.267 337.97 383.09 338.098C381.983 338.902 380.996 339.858 380.158 340.938L378.968 341.036C378.751 341.052 378.543 341.13 378.369 341.262C378.195 341.393 378.063 341.571 377.988 341.776C376.92 344.741 376.874 347.978 377.857 350.973C377.857 350.973 391.62 354.136 391.67 354.083L394.382 351.053C394.525 350.892 394.619 350.693 394.651 350.481C394.683 350.268 394.652 350.05 394.563 349.854C394.312 349.314 394.028 348.79 393.711 348.285L394.892 345.893C394.985 345.699 395.019 345.482 394.991 345.269C394.963 345.055 394.873 344.855 394.733 344.691Z"
                fill="#444B53"
            />
            <path
                d="M386.184 339.216C387.784 339.091 389.393 339.228 390.949 339.623C391.126 339.666 391.287 339.76 391.412 339.893C391.537 340.025 391.621 340.192 391.653 340.371L391.74 340.866C391.857 340.838 391.976 340.816 392.093 340.794L392.01 340.313C391.965 340.065 391.849 339.836 391.677 339.652C391.504 339.468 391.282 339.338 391.038 339.278C389.461 338.879 387.831 338.738 386.209 338.86L384.407 337.87C384.185 337.748 383.933 337.691 383.68 337.707C383.428 337.722 383.184 337.808 382.979 337.956C381.879 338.755 380.895 339.701 380.055 340.769L378.941 340.858C378.689 340.877 378.448 340.969 378.247 341.121C378.046 341.274 377.894 341.481 377.807 341.717C377.315 343.083 377.033 344.515 376.972 345.965C377.094 345.965 377.214 345.949 377.334 345.946C377.397 344.542 377.671 343.156 378.147 341.834C378.211 341.661 378.324 341.51 378.472 341.399C378.621 341.289 378.798 341.223 378.982 341.211L380.252 341.108L380.3 341.044C381.128 339.979 382.102 339.035 383.193 338.24C383.343 338.133 383.519 338.07 383.703 338.058C383.887 338.047 384.07 338.088 384.232 338.176L386.128 339.222L386.184 339.216Z"
                fill="black"
            />
            <path
                d="M394.872 344.577C394.125 343.703 393.277 342.92 392.347 342.243L392.068 340.646C392.064 340.623 392.055 340.601 392.042 340.582C392.029 340.562 392.013 340.545 391.993 340.532C391.974 340.519 391.952 340.51 391.929 340.505C391.906 340.5 391.882 340.5 391.859 340.505C391.836 340.508 391.815 340.516 391.795 340.528C391.775 340.54 391.758 340.555 391.745 340.574C391.731 340.593 391.721 340.614 391.716 340.636C391.711 340.659 391.71 340.682 391.714 340.705L392.018 342.443L392.076 342.485C393.007 343.155 393.853 343.933 394.597 344.805C394.715 344.943 394.79 345.112 394.813 345.292C394.836 345.472 394.805 345.655 394.725 345.818L393.499 348.294L393.55 348.377C393.861 348.876 394.14 349.393 394.385 349.927C394.461 350.091 394.488 350.275 394.461 350.454C394.434 350.633 394.356 350.801 394.235 350.936L391.628 353.846C391.699 353.946 391.765 354.049 391.829 354.155L394.505 351.17C394.672 350.984 394.782 350.753 394.819 350.506C394.856 350.259 394.819 350.006 394.714 349.779C394.472 349.261 394.201 348.758 393.901 348.271L395.039 345.968C395.15 345.745 395.192 345.494 395.162 345.247C395.131 345 395.029 344.767 394.867 344.577H394.872Z"
                fill="#EA7969"
            />
            <path
                d="M360.068 436.718L357.963 379.553C357.939 378.747 358.185 377.956 358.662 377.306C359.139 376.656 359.82 376.184 360.597 375.965L374.541 371.934H384.613L392.355 373.659C398.111 374.941 397.73 380.015 397.601 385.799L401.756 438.309L383.346 444.579L360.068 436.718Z"
                fill="#EA7969"
            />
            <path
                d="M401.573 436.122L379.085 436.737C378.553 441.11 378.389 455.679 380.639 468.261L385.114 512.854C386.228 525.951 388.646 539.254 390.227 544.69L394.271 545.291C397.574 535.813 400.829 517.633 400.706 502.937C400.706 502.937 403.488 446.323 401.573 436.122Z"
                fill="#121416"
            />
            <path
                d="M391.781 351.537C394.009 346.597 388.055 340.51 382.926 341.662C378.325 342.694 375.351 349.281 374.674 353.446C373.936 357.988 376.276 362.626 381.547 361.457C386.563 360.35 389.81 355.927 391.781 351.537Z"
                fill="#EA7969"
            />
            <path
                d="M379.556 446.794C379.486 443.664 379.712 440.562 379.085 436.556L357.4 438.32C356.384 443.049 352.062 468.77 353.134 484.616C353.134 489.929 353.346 495.084 353.783 500.333C354.744 516.938 358.083 536.158 360.143 544.286H364.685C370.856 528.38 372.168 504.506 369.536 488.56C376.033 476.857 379.768 456.335 379.556 446.794Z"
                fill="#121416"
            />
            <path
                d="M401.572 436.122L379.085 436.375L357.4 438.323C357.122 439.655 356.565 442.657 355.947 446.638C359.784 444.69 369.559 441.998 379.617 439.808C389.222 437.719 397.635 436.659 401.698 436.901C401.659 436.616 401.617 436.356 401.572 436.122Z"
                fill="#121416"
            />
            <path
                d="M400.328 377.066C395.819 369.697 374.621 368.721 367.623 367.196C363.549 366.309 356.186 370.813 353.978 380.204C352.864 384.95 350.767 395.226 349.074 405.347C348.606 408.145 354.276 415.918 354.276 415.918V443.917C355.111 442.045 367.136 438.551 379.603 435.85C392.873 432.962 403.864 432.039 404.263 433.802L401.912 406.54C401.912 406.54 407.17 402.737 407.145 400.437C407.089 394.575 403.597 382.408 400.328 377.066Z"
                fill="#444B53"
            />
            <path
                d="M378.556 347.662C378.487 348.939 377.651 349.932 376.696 349.888C375.741 349.843 375.025 348.775 375.095 347.484C375.164 346.193 376 345.214 376.958 345.259C377.916 345.303 378.629 346.385 378.556 347.662Z"
                fill="#FFCECE"
            />
            <path
                d="M391.547 356.013C392.647 355.326 393.121 354.127 392.608 353.334C392.096 352.542 390.784 352.461 389.684 353.148C388.584 353.835 388.111 355.034 388.626 355.824C389.141 356.614 390.461 356.7 391.547 356.013Z"
                fill="#EA7969"
            />
            <path
                d="M357.086 416.424C361.707 416.424 365.454 412.495 365.454 407.647C365.454 402.8 361.707 398.871 357.086 398.871C352.464 398.871 348.717 402.8 348.717 407.647C348.717 412.495 352.464 416.424 357.086 416.424Z"
                fill="#EA7969"
            />
            <path
                d="M407.248 400.637C407.248 405.486 403.411 409.503 398.791 409.503C394.171 409.503 391.489 405.703 389.946 402.807C387.657 398.512 394.171 392.088 398.791 391.958C404.898 391.768 407.248 395.791 407.248 400.637Z"
                fill="#EA7969"
            />
            <path
                d="M385.755 368.114C386.131 381.345 367.573 387.209 373.669 368.042C375.897 366.295 376.256 361.188 375.34 358.378C378.225 359.306 381.24 359.762 384.271 359.73C383.115 362.676 384.803 366.234 385.755 368.114Z"
                fill="#FFCECE"
            />
            <path
                d="M376.585 382.444C378.963 382.444 381.539 381.223 383.65 379.106C384.992 377.742 386.056 376.13 386.783 374.36C387.208 373.323 387.517 372.243 387.704 371.138C387.014 370.927 386.316 370.726 385.61 370.535C384.727 376.413 380.105 380.332 376.585 380.332C373.243 380.332 370.887 376.799 373.669 368.053C373.549 367.888 373.398 367.748 373.224 367.642C373.049 367.535 372.855 367.465 372.653 367.434C372.451 367.404 372.245 367.414 372.047 367.464C371.849 367.514 371.663 367.602 371.5 367.725C370.664 370.785 370.238 372.883 370.263 374.927C370.294 377.075 370.804 378.822 371.784 380.135C372.344 380.872 373.071 381.466 373.906 381.867C374.74 382.269 375.659 382.466 376.585 382.444Z"
                fill="white"
            />
            <path
                d="M382.664 342.04C380.876 342.441 379.322 343.679 378.069 345.284C376.357 347.724 375.202 350.511 374.686 353.446C373.948 357.989 376.287 362.626 381.558 361.457C386.137 360.448 389.233 356.684 391.241 352.697C391.354 352.458 391.466 352.219 391.575 351.98C393.803 347.006 387.813 340.883 382.664 342.04Z"
                fill="#FFCECE"
            />
            <path
                d="M385.755 368.114C384.889 366.406 383.418 363.316 384.023 360.559C384.789 360.166 385.515 359.7 386.192 359.168C385.357 357.154 384.042 353.994 383.714 353.326C383.157 352.197 380.28 352.322 381.035 355.215L381.675 358.136L374.621 356.33C374.74 357.309 375.052 358.254 375.538 359.112C375.852 360.69 375.887 362.31 375.641 363.9C375.863 364.234 376.098 364.561 376.345 364.882C377.181 365.947 378.197 366.935 379.472 367.469C380.189 367.757 380.947 367.928 381.717 367.975L382.987 376.599C384.881 374.185 385.861 371.18 385.755 368.114V368.114Z"
                fill="#EA7969"
            />
            <path
                d="M352.374 411.239C358.347 421.648 397.29 402.532 396.254 396.818C395.418 392.152 370.397 396.389 360.954 398.676C354.32 400.275 348.798 405.005 352.374 411.239Z"
                fill="#FFCECE"
            />
            <path
                d="M386.231 394.795C387.387 398.019 388.737 400.946 390.23 403.241C394.087 400.698 396.521 398.303 396.254 396.818C395.917 394.956 391.734 394.511 386.231 394.795Z"
                fill="#EA7969"
            />
            <path
                d="M358.509 400.604C365.34 398.562 379.974 395.115 388.921 394.717C380.567 394.742 367.275 397.143 360.954 398.676C357.551 399.51 354.44 401.143 352.702 403.405C354.466 402.151 356.429 401.204 358.509 400.604V400.604Z"
                fill="#FFE0E0"
            />
            <path
                d="M399.626 406.732C387.064 408.921 380.133 364.912 383.588 361.165C387.075 357.376 400.882 381.261 403.433 391.045C404.608 395.546 407.145 405.422 399.626 406.732Z"
                fill="#FFCECE"
            />
            <path
                d="M388.401 363.491C387.713 363.744 385.652 366.468 385.652 366.468L379.564 366.145C377.47 366.031 377.629 364.1 378.69 364.128H378.654C376.239 364.111 376.574 361.797 377.701 361.777C374.953 361.719 374.947 359.513 376.1 359.666C375.412 359.619 373.02 359.046 373.02 357.362C373.02 355.947 377.835 357.955 382.358 358.982C382.009 357.312 381.82 356.648 381.631 355.273C381.57 354.959 381.561 354.637 381.603 354.319C381.625 354.162 381.686 354.012 381.78 353.884C381.874 353.755 381.997 353.652 382.14 353.582C382.299 353.533 382.468 353.53 382.629 353.573C382.789 353.616 382.935 353.703 383.048 353.824C383.279 354.072 383.453 354.366 383.561 354.686C384.126 356.091 385.897 361.449 388.401 363.491Z"
                fill="#FFCECE"
            />
            <path
                d="M378.317 360.147H378.295L375.758 359.908C375.699 359.902 375.644 359.874 375.606 359.828C375.568 359.783 375.55 359.725 375.554 359.666C375.562 359.607 375.592 359.554 375.638 359.517C375.685 359.48 375.743 359.463 375.802 359.468L378.339 359.708C378.398 359.711 378.452 359.737 378.491 359.78C378.531 359.823 378.551 359.88 378.548 359.938C378.545 359.997 378.519 360.051 378.476 360.091C378.432 360.13 378.375 360.15 378.317 360.147V360.147Z"
                fill="#EA7969"
            />
            <path
                d="M378.785 362.164H378.765L377.373 362.05C377.344 362.048 377.315 362.04 377.289 362.027C377.262 362.014 377.239 361.995 377.22 361.973C377.201 361.951 377.186 361.925 377.177 361.897C377.168 361.869 377.164 361.84 377.167 361.811C377.173 361.752 377.202 361.697 377.248 361.659C377.294 361.622 377.352 361.603 377.412 361.608L378.804 361.725C378.863 361.729 378.917 361.757 378.955 361.802C378.993 361.847 379.012 361.905 379.007 361.964C379.002 362.019 378.976 362.07 378.935 362.107C378.894 362.144 378.84 362.164 378.785 362.164Z"
                fill="#EA7969"
            />
            <path
                d="M379.648 364.579H379.623L378.35 364.434C378.292 364.428 378.238 364.398 378.201 364.352C378.164 364.307 378.146 364.248 378.153 364.189C378.16 364.13 378.191 364.077 378.238 364.04C378.285 364.004 378.344 363.987 378.403 363.994L379.676 364.139C379.731 364.148 379.78 364.176 379.815 364.22C379.85 364.263 379.867 364.318 379.864 364.373C379.86 364.428 379.836 364.48 379.796 364.519C379.756 364.557 379.704 364.58 379.648 364.581V364.579Z"
                fill="#EA7969"
            />
            <path
                d="M364.206 546.601L358.358 546.701C358.214 546.712 358.069 546.689 357.934 546.635C357.799 546.582 357.679 546.498 357.581 546.391C357.484 546.283 357.412 546.155 357.372 546.016C357.331 545.877 357.323 545.731 357.347 545.588V545.588C357.403 545.265 357.567 544.97 357.814 544.753C358.061 544.536 358.375 544.411 358.704 544.398L364.794 544.261C364.941 544.246 365.09 544.268 365.226 544.325C365.363 544.382 365.482 544.474 365.574 544.59C365.665 544.706 365.726 544.843 365.75 544.989C365.774 545.135 365.76 545.285 365.71 545.424V545.424C365.61 545.75 365.412 546.038 365.143 546.248C364.874 546.459 364.547 546.582 364.206 546.601V546.601Z"
                fill="#EA7969"
            />
            <path
                d="M359.043 548.857L359.661 544.871C359.696 544.685 359.688 544.494 359.638 544.311C359.587 544.129 359.495 543.961 359.369 543.82C359.243 543.679 359.087 543.569 358.912 543.498C358.736 543.427 358.547 543.397 358.358 543.41C357.314 543.432 356.481 544.456 356.707 545.388L357.626 549.166L359.043 548.857Z"
                fill="#FEF1EF"
            />
            <path
                d="M365.15 546.228L365.866 552.626L359.681 553.497H352.805L350.694 552.663C350.694 551.536 351.613 550.507 352.936 550.217L359.043 548.854L359.436 546.32L365.15 546.228Z"
                fill="#444B53"
            />
            <path
                d="M365.543 553.692H350.694V552.665L365.866 552.629L365.543 553.692Z"
                fill="#FEF1EF"
            />
            <path
                d="M365.317 547.714C364.841 547.835 364.405 548.079 364.051 548.421C363.698 548.762 363.439 549.189 363.301 549.661C363.051 550.718 363.811 551.541 364.994 551.536C365.245 551.562 365.499 551.525 365.732 551.43C366.328 551.099 366.122 547.516 365.317 547.714Z"
                fill="#FEF1EF"
            />
            <path
                d="M357.437 550.721C357.399 550.722 357.362 550.712 357.331 550.69C357.312 550.677 357.296 550.66 357.285 550.64C357.274 550.62 357.267 550.598 357.266 550.575C357.265 550.553 357.269 550.53 357.277 550.509C357.286 550.488 357.3 550.469 357.317 550.454C357.415 550.355 357.48 550.227 357.5 550.089C357.521 549.95 357.497 549.809 357.431 549.686C357.344 549.569 357.225 549.479 357.088 549.43C356.95 549.38 356.801 549.373 356.66 549.408C356.636 549.415 356.612 549.417 356.588 549.414C356.564 549.411 356.54 549.403 356.52 549.39C356.499 549.378 356.481 549.361 356.467 549.341C356.453 549.321 356.444 549.298 356.44 549.274C356.437 549.224 356.454 549.174 356.486 549.135C356.517 549.095 356.562 549.069 356.612 549.06C356.829 549.007 357.057 549.021 357.266 549.098C357.475 549.176 357.655 549.315 357.785 549.497C357.884 549.684 357.921 549.898 357.888 550.107C357.856 550.316 357.756 550.509 357.604 550.657C357.558 550.699 357.498 550.722 357.437 550.721Z"
                fill="white"
            />
            <path
                d="M356.022 550.982C355.986 550.983 355.951 550.973 355.921 550.954C355.902 550.943 355.885 550.928 355.873 550.909C355.861 550.89 355.853 550.869 355.85 550.847C355.847 550.824 355.849 550.802 355.856 550.78C355.863 550.759 355.875 550.74 355.891 550.724C355.985 550.617 356.041 550.481 356.051 550.339C356.061 550.197 356.023 550.055 355.944 549.936C355.844 549.818 355.712 549.732 355.563 549.688C355.414 549.645 355.256 549.646 355.108 549.692C355.062 549.707 355.012 549.703 354.969 549.681C354.925 549.659 354.893 549.621 354.877 549.575C354.869 549.526 354.88 549.476 354.907 549.435C354.935 549.394 354.977 549.366 355.025 549.355C355.245 549.288 355.481 549.287 355.702 549.353C355.923 549.419 356.119 549.548 356.267 549.725C356.386 549.903 356.442 550.116 356.426 550.33C356.41 550.544 356.322 550.746 356.178 550.904C356.138 550.95 356.082 550.978 356.022 550.982V550.982Z"
                fill="white"
            />
            <path
                d="M394.884 545.132L395.084 552.487H390.041L389.676 545.36L394.884 545.132Z"
                fill="#444B53"
            />
            <path
                d="M394.861 546.206H389.821C389.56 546.205 389.309 546.101 389.124 545.917C388.94 545.732 388.836 545.482 388.835 545.221C388.835 544.96 388.939 544.709 389.124 544.524C389.308 544.339 389.559 544.234 389.821 544.234H394.861C395.123 544.234 395.374 544.339 395.558 544.524C395.743 544.709 395.847 544.96 395.847 545.221C395.846 545.482 395.742 545.732 395.558 545.917C395.373 546.101 395.122 546.205 394.861 546.206Z"
                fill="#EA7969"
            />
            <path
                d="M392.469 543.391C391.355 543.277 390.417 544.656 390.662 546.067L391.859 552.365L393.391 552.521L393.87 545.402C393.884 544.339 393.282 543.474 392.469 543.391Z"
                fill="white"
            />
            <path
                d="M394.444 548.771C394.424 548.773 394.404 548.773 394.385 548.771L390.486 547.458C390.442 547.442 390.405 547.41 390.384 547.367C390.364 547.325 390.361 547.276 390.376 547.231C390.392 547.186 390.425 547.15 390.467 547.129C390.51 547.108 390.559 547.106 390.603 547.121L394.502 548.431C394.547 548.447 394.585 548.479 394.606 548.522C394.628 548.565 394.631 548.614 394.616 548.659C394.601 548.693 394.577 548.721 394.547 548.741C394.516 548.761 394.48 548.771 394.444 548.771V548.771Z"
                fill="white"
            />
            <path
                d="M390.935 548.901C390.902 548.902 390.871 548.893 390.843 548.877C390.815 548.861 390.792 548.838 390.776 548.81C390.764 548.789 390.756 548.767 390.753 548.743C390.75 548.72 390.752 548.696 390.758 548.674C390.765 548.651 390.776 548.63 390.791 548.612C390.806 548.593 390.824 548.578 390.846 548.568L393.973 546.86C394.015 546.836 394.065 546.831 394.111 546.844C394.157 546.857 394.197 546.887 394.221 546.929C394.232 546.95 394.239 546.972 394.242 546.995C394.244 547.019 394.243 547.042 394.236 547.065C394.23 547.087 394.219 547.108 394.204 547.126C394.19 547.145 394.172 547.16 394.151 547.171L391.024 548.879C390.996 548.893 390.966 548.901 390.935 548.901V548.901Z"
                fill="white"
            />
            <path
                d="M395.752 552.265C395.752 550.384 394.296 548.86 392.5 548.86C390.704 548.86 389.25 550.384 389.25 552.265C389.25 554.145 395.752 554.145 395.752 552.265Z"
                fill="black"
            />
            <path
                d="M396.078 552.398H389.013C388.893 552.398 388.796 552.495 388.796 552.615V553.505C388.796 553.625 388.893 553.722 389.013 553.722H396.078C396.198 553.722 396.295 553.625 396.295 553.505V552.615C396.295 552.495 396.198 552.398 396.078 552.398Z"
                fill="#FEF1EF"
            />
            <path
                d="M403.433 391.045C402.157 386.158 398.078 377.754 393.875 371.038C397.821 378.886 401.628 389.61 402.408 395.416C402.748 397.944 403.285 402.039 402.589 405.43C406.334 402.292 404.413 394.804 403.433 391.045Z"
                fill="#EA7969"
            />
            <path
                d="M276.182 494.363C276.595 484.858 279.85 475.694 285.528 468.055C286.143 467.221 284.759 466.417 284.135 467.237C278.311 475.124 274.982 484.571 274.575 494.363C274.531 495.404 276.126 495.401 276.171 494.363H276.182Z"
                fill="#EA7969"
            />
            <path
                d="M336.993 420.118C336.945 419.796 336.788 419.499 336.55 419.277C336.311 419.055 336.004 418.919 335.679 418.893C335.354 418.866 335.029 418.951 334.757 419.132C334.486 419.313 334.284 419.58 334.183 419.89C331.209 428.903 323.509 436.35 314.295 438.403C306.322 440.181 297.658 438.292 290.089 441.391C282.718 444.407 277.583 452.31 277.761 460.358C277.939 468.406 283.289 476.493 290.922 478.746C307.812 483.728 319.452 475.241 328.906 461.457C337.027 449.614 339.346 434.411 336.993 420.118Z"
                fill="#EA7969"
            />
            <path
                d="M254.5 450.858C264.003 463.913 269.266 479.568 269.577 495.707C269.596 496.747 271.192 496.75 271.172 495.707C270.869 479.281 265.531 463.343 255.878 450.043C255.274 449.208 253.887 450.021 254.5 450.858V450.858Z"
                fill="#EA7969"
            />
            <path
                d="M265.285 494.505C263.113 485.603 260.476 475.792 252.444 470.445C244.09 464.881 234.321 468.726 226.49 473.321C225.602 473.844 226.404 475.244 227.295 474.712C234.795 470.311 244.112 466.514 252.038 472.111C259.384 477.294 261.718 486.624 263.734 494.922C263.982 495.932 265.522 495.507 265.274 494.494L265.285 494.505Z"
                fill="#EA7969"
            />
            <path
                d="M240.231 469.085C238.56 463.799 233.478 458.692 228 458.655C215.257 458.569 211.213 464.728 209.019 467.129C204.697 471.858 202.614 478.209 199.161 483.62C196.523 487.756 192.41 491.54 187.846 492.474C187.636 492.523 187.447 492.635 187.304 492.795C187.161 492.955 187.07 493.155 187.046 493.369C187.021 493.582 187.063 493.798 187.166 493.986C187.269 494.175 187.428 494.327 187.62 494.422C193.424 497.173 199.342 499.707 205.607 500.984C213.355 502.564 221.812 502.052 228.56 497.888C233.258 494.992 236.88 490.452 239.036 485.325C241.191 480.198 241.896 474.37 240.231 469.085Z"
                fill="#EA7969"
            />
            <path
                d="M267.24 433.36C263.581 426.553 257.803 421.228 252.673 415.475C248.418 410.705 244.441 405.269 242.807 399.149C242.746 398.918 242.614 398.713 242.429 398.564C242.243 398.414 242.015 398.328 241.777 398.318C241.539 398.307 241.304 398.374 241.106 398.507C240.908 398.64 240.759 398.833 240.679 399.057C236.237 411.503 234.244 424.688 234.809 437.889C235.045 443.341 235.75 448.88 238.042 453.818C240.334 458.756 244.413 463.062 249.604 464.522C255.135 466.08 261.49 464.008 265.085 459.479C265.883 458.459 266.538 457.336 267.034 456.141C267.224 455.904 267.41 455.659 267.591 455.406C272.016 449.086 270.899 440.167 267.24 433.36Z"
                fill="#EA7969"
            />
            <path
                d="M327.286 443.394C327.767 442.56 326.5 441.82 326.021 442.643C324.526 445.208 323.014 447.812 321.154 450.126C321.554 447.14 321.109 444.102 319.87 441.355C319.483 440.484 318.218 441.238 318.603 442.103C320.03 445.33 320.228 448.967 319.16 452.329C318.993 452.494 318.82 452.655 318.647 452.816C315.384 455.798 311.749 456.878 307.416 456.989C303.874 457.075 300.527 457.401 297.218 458.781C298.799 455.596 300.178 452.315 301.348 448.958C301.663 448.054 300.234 447.664 299.933 448.563C298.591 452.444 296.959 456.219 295.052 459.857C292.545 461.227 290.31 463.042 288.455 465.212C287.839 465.933 288.872 466.987 289.493 466.264C291.293 464.144 293.473 462.377 295.921 461.053L295.965 461.031C296.989 460.479 298.058 460.014 299.159 459.64C301.666 458.806 304.172 458.7 306.717 458.586C311.379 458.903 315.902 460.306 319.923 462.684C320.741 463.165 321.479 461.88 320.663 461.401C318.063 459.87 315.256 458.72 312.329 457.985C319.48 456.271 323.754 449.472 327.286 443.394Z"
                fill="#FAFAFA"
            />
            <path
                d="M233.931 469.822C227.911 470.03 224.435 474.754 220.918 479.116H220.893C217.683 480.572 214.088 480.95 210.645 480.195C209.684 479.989 209.272 481.483 210.239 481.692C213.123 482.325 216.12 482.238 218.963 481.439C218.679 481.761 218.39 482.075 218.094 482.379C216.185 484.343 214.005 486.024 211.62 487.372C211.507 487.4 211.401 487.453 211.311 487.525L211.266 487.562C207.442 489.662 203.199 490.886 198.843 491.145C197.863 491.2 197.857 492.753 198.843 492.694C202.128 492.505 205.361 491.796 208.423 490.594C207.184 492.382 206.369 494.43 206.042 496.58C205.891 497.557 207.367 497.971 207.518 496.995C207.984 493.8 209.626 490.893 212.124 488.844C213.832 487.898 215.447 486.793 216.947 485.545C218.785 484.009 220.314 482.207 221.801 480.379C221.9 480.309 221.976 480.213 222.021 480.101C222.737 479.21 223.447 478.312 224.179 477.427C225.373 475.931 226.752 474.591 228.281 473.438C227.077 476.077 226.627 478.997 226.981 481.876C227.1 482.852 228.632 482.863 228.512 481.876C228.065 478.438 228.875 474.955 230.793 472.067L230.81 472.033C231.803 471.629 232.86 471.401 233.931 471.36C234.914 471.343 234.917 469.791 233.931 469.822Z"
                fill="#FAFAFA"
            />
            <path
                d="M260.754 455.89C259.833 453.818 258.379 452.16 256.778 450.627C256.772 450.602 256.764 450.578 256.755 450.555C256.159 449.069 256.096 447.423 256.576 445.895C257.056 444.368 258.05 443.054 259.39 442.176C260.206 441.658 259.454 440.345 258.635 440.865C257.328 441.711 256.279 442.899 255.602 444.299C254.925 445.7 254.646 447.259 254.795 448.808C252.347 446.582 250.2 444.259 248.668 441.255C246.964 437.917 246.037 434.353 245.519 430.684C246.546 427.49 248.466 424.656 251.052 422.516C251.798 421.896 250.732 420.828 249.994 421.445C247.976 423.126 246.332 425.211 245.171 427.565C244.906 424.489 244.853 421.384 244.803 418.341C244.786 417.364 243.288 417.361 243.305 418.341C243.372 422.377 243.461 426.483 244.004 430.5C243.979 430.588 243.973 430.68 243.985 430.771C243.998 430.861 244.029 430.948 244.076 431.026C244.528 434.44 245.466 437.772 246.861 440.921C244.96 439.476 242.891 438.266 240.698 437.318C239.821 436.94 239.058 438.248 239.944 438.631C243.446 440.133 246.612 442.319 249.256 445.06L249.309 445.105C250.354 446.534 251.539 447.857 252.846 449.052C255.338 451.345 258.056 453.45 259.476 456.636C259.855 457.543 261.15 456.772 260.754 455.89Z"
                fill="#FAFAFA"
            />
            <path
                d="M248.994 490.875C246.209 507.53 255.881 540.765 255.881 540.765H282.512C282.512 540.765 292.815 507.555 290.262 490.875H248.994Z"
                fill="#E3282A"
            />
            <path
                d="M290.159 505.477C288.368 521.909 282.515 540.765 282.515 540.765H255.884C255.884 540.765 255.09 538.038 254.024 533.757C262.926 532.532 271.27 528.713 278.012 522.777C283.338 518.02 287.495 512.1 290.159 505.477Z"
                fill="#E3282A"
            />
            <path
                d="M288.661 501.565C286.656 500.372 284.578 499.142 282.197 498.884C278.897 498.528 275.901 500.102 273.007 501.627C270.523 502.931 267.956 504.28 265.257 504.356C262.559 504.431 260.08 503.296 257.318 501.919L257.237 501.88C254.631 500.581 251.946 499.237 248.978 499.167C248.824 499.167 248.668 499.167 248.51 499.167C248.51 499.51 248.526 499.852 248.538 500.199C248.677 500.199 248.816 500.199 248.955 500.199C251.687 500.263 254.28 501.557 256.786 502.806L256.87 502.845C259.563 504.189 262.216 505.391 265.021 505.391H265.285C268.218 505.31 270.891 503.905 273.475 502.545C276.366 501.026 279.095 499.59 282.094 499.913C284.255 500.147 286.146 501.271 288.148 502.458C288.864 502.884 289.594 503.315 290.34 503.707C290.373 503.332 290.407 502.959 290.435 502.595C289.833 502.261 289.243 501.91 288.661 501.565Z"
                fill="white"
            />
            <path
                d="M118.193 406.576L106.597 401.513L130.733 319.362L142.329 324.425L118.193 406.576Z"
                fill="black"
            />
            <path
                d="M99.5907 504.498L138.856 355.129C139.444 352.903 138.511 350.528 136.748 349.754L122.61 343.526C120.855 342.752 118.903 343.846 118.182 346.001L69.6097 491.242C67.2343 498.344 70.0747 506.386 75.8587 508.929L85.5414 513.185C91.3004 515.722 97.6775 511.775 99.5907 504.498Z"
                fill="#24282D"
            />
            <path
                d="M71.5646 485.4C72.6794 486.428 73.9549 487.267 75.3407 487.884V487.884C82.9905 491.245 91.4785 486.232 94.3023 476.685L121.835 383.562C124.62 374.143 120.847 363.828 113.398 360.328L71.5646 485.4Z"
                fill="black"
            />
            <path
                d="M113.581 356.753L109.404 368.379C109.112 369.191 109.443 370.131 110.125 370.429L132.214 380.074C132.891 380.368 133.635 379.904 133.838 379.058L136.731 366.874C136.918 366.098 136.575 365.282 135.954 365.01L115.149 355.927C114.528 355.657 113.846 356.019 113.581 356.753Z"
                fill="#E3282A"
            />
            <path
                d="M138.511 337.422L142.329 324.425L130.733 319.362L127.856 329.146C130.479 333.311 134.231 336.293 138.511 337.422Z"
                fill="black"
            />
            <path
                opacity="0.22"
                d="M237.178 268.769C251.107 226.129 227.818 180.289 185.16 166.383C142.501 152.478 96.6285 175.773 82.6994 218.414C68.7703 261.055 92.0598 306.894 134.718 320.8C177.376 334.705 223.249 311.41 237.178 268.769Z"
                fill="white"
            />
            <path
                d="M241.244 262.799C236.056 282.236 224.223 299.247 207.798 310.884C191.373 322.521 171.389 328.051 151.311 326.516C131.232 324.98 112.324 316.476 97.8637 302.477C83.403 288.477 74.3007 269.866 72.1344 249.866C69.9681 229.867 74.8742 209.741 86.0026 192.976C97.131 176.211 113.78 163.863 133.065 158.073C152.35 152.282 173.055 153.414 191.592 161.272C210.129 169.13 225.33 183.219 234.561 201.097C244.405 220.115 246.789 242.118 241.244 262.799ZM89.0697 222.301C84.8668 238.074 86.2169 254.811 92.8937 269.708C99.5705 284.606 111.169 296.759 125.746 304.133C140.323 311.507 156.994 313.654 172.966 310.214C188.939 306.774 203.243 297.956 213.484 285.237C223.725 272.519 229.28 256.671 229.219 240.349C229.158 224.026 223.484 208.221 213.149 195.578C202.813 182.936 188.443 174.225 172.446 170.905C156.448 167.584 139.793 169.855 125.272 177.338C116.426 181.887 108.633 188.239 102.398 195.984C96.1619 203.729 91.6215 212.694 89.0697 222.301Z"
                fill="black"
            />
            <path
                d="M122.718 375.929C122.326 368.974 118.719 362.654 113.097 360.181C112.88 360.086 112.662 360 112.442 359.916L109.401 368.379C109.109 369.191 109.44 370.131 110.123 370.429L122.718 375.929Z"
                fill="#E3282A"
            />
            <path
                d="M104.921 385.337L78.8801 463.193C82.751 462.836 86.1985 459.974 87.513 455.49L105.494 394.678C106.39 391.592 106.188 388.291 104.921 385.337Z"
                fill="black"
            />
            <path
                d="M97.7804 274.296C91.3763 261.891 88.7627 247.879 90.2643 234.002C91.7659 220.125 97.3166 206.995 106.226 196.243C115.136 185.491 127.013 177.592 140.38 173.527C153.746 169.462 168.014 169.411 181.41 173.379C180.82 173.173 180.224 172.973 179.622 172.784C170.713 169.913 161.323 168.837 151.994 169.619C142.666 170.401 133.586 173.024 125.28 177.338C112.746 183.789 102.408 193.808 95.5739 206.129C88.7399 218.449 85.7167 232.517 86.8867 246.554C88.0566 260.591 93.3672 273.966 102.147 284.987C110.926 296.009 122.781 304.183 136.21 308.474C136.709 308.633 136.21 308.474 136.709 308.633C119.407 302.596 106.202 290.643 97.7804 274.296Z"
                fill="#444B53"
            />
            <path
                d="M113.353 185.066C121.074 178.883 130 174.374 139.561 171.827C149.123 169.279 159.111 168.75 168.889 170.271C178.666 171.792 188.02 175.331 196.353 180.663C204.686 185.994 211.816 193.001 217.289 201.237C218.204 202.615 219.554 203.649 221.124 204.173C222.694 204.698 224.395 204.682 225.956 204.13L226.098 204.08C227.159 203.706 228.121 203.097 228.911 202.297C229.702 201.498 230.3 200.529 230.661 199.465C231.022 198.401 231.136 197.268 230.994 196.153C230.852 195.039 230.459 193.971 229.843 193.03C223.46 183.416 215.21 175.179 205.584 168.806C195.957 162.432 185.148 158.053 173.797 155.926C162.447 153.799 150.784 153.968 139.5 156.423C128.215 158.878 117.538 163.569 108.101 170.219L113.353 185.066Z"
                fill="black"
            />
            <path
                d="M81.2639 280.144L94.5864 273.275C94.5864 273.275 80.7933 247.997 89.0697 222.301C93.9793 207.062 102.612 193.325 115.091 183.764C116.431 182.742 117.382 181.293 117.787 179.658C118.192 178.023 118.027 176.299 117.319 174.77C117.288 174.7 117.255 174.631 117.224 174.564C116.76 173.564 116.078 172.68 115.229 171.977C114.38 171.273 113.384 170.768 112.314 170.498C111.244 170.228 110.128 170.2 109.046 170.416C107.964 170.632 106.943 171.086 106.06 171.746C100.174 176.16 94.8847 181.316 90.3228 187.085C82.9543 196.388 78.2397 207.115 74.5888 218.442C64.6416 249.349 81.2639 280.144 81.2639 280.144Z"
                fill="black"
            />
            <path
                d="M153.599 322.105C154.136 318.714 154.668 315.212 154.852 311.712C154.534 311.712 154.217 311.684 153.902 311.668C140.519 310.921 127.618 306.421 116.678 298.684C105.738 290.947 97.2018 280.287 92.0494 267.926C91.509 266.628 90.6135 265.507 89.4656 264.694C88.3176 263.88 86.9635 263.406 85.5582 263.325C84.2917 263.251 83.0274 263.5 81.8835 264.048C80.7397 264.596 79.7538 265.425 79.0183 266.458C78.2828 267.49 77.8217 268.692 77.6782 269.951C77.5347 271.21 77.7135 272.485 78.1979 273.657C84.4166 288.589 94.7141 301.473 107.914 310.837C121.114 320.201 136.685 325.667 152.847 326.612C153.095 325.107 153.362 323.605 153.599 322.105Z"
                fill="black"
            />
            <path
                d="M226.76 258.941C231.478 241.279 229.213 222.481 220.436 206.442C211.658 190.403 197.041 178.351 179.611 172.784C179.004 172.589 178.395 172.404 177.785 172.227C194.969 178.283 210.286 190.888 218.679 207.142C227.338 223.929 228.971 243.463 223.22 261.453C217.469 279.442 204.804 294.414 188.007 303.08C171.658 311.509 154.353 313.746 136.767 308.644C137.453 308.883 137.196 308.752 137.881 308.975C156.008 314.758 173.652 312.636 190.569 303.915C199.402 299.346 207.184 292.987 213.415 285.242C219.647 277.498 224.192 268.54 226.76 258.941Z"
                fill="black"
            />
        </svg>
    );
}
