import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import HowThisWorkImageIcon from "../icons/howThisWorkImage";
const steps = [
    {
        id: 1,
        text: "Fill out the form above, as it helps us match you with the ideal lawyer for your case based on your responses and location.",
    },
    {
        id: 2,
        text: "Upon matching, our attorney will contact you for a free consultation, where they will start building your case by asking questions.",
    },
    {
        id: 3,
        text: "Maximize compensation! Our attorneys will file your case and fight insurance companies for fair compensation.",
    },
];
export default function HowThisWork(): ReactElement {
    return (
        <div
            className={`flex ${styles["howThisWork"]} flex-col md:flex-row max-w-7xl mx-auto  px-4vw lg:px-4`}
        >
            <div className={`${styles["howThisWorkListWrapper"]}`}>
                <h2
                    className={`bold capitalize mb-12 text-3xl text-center md:text-left md:text-4xl `}
                >
                    How It Works
                </h2>
                <div>
                    {steps.map((step) => (
                        <div
                            className={`flex justify-between mb-12`}
                            key={step.id}
                        >
                            <div
                                className={`flex justify-center items-center bold ${styles["stepNum"]}`}
                            >
                                {step.id}
                            </div>
                            <div
                                className={`${styles["stepText"]} text-base md:text-xl`}
                            >
                                {step.text}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={` ${styles["howThisWorkImage"]}`}>
                <HowThisWorkImageIcon />
            </div>
        </div>
    );
}
